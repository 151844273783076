
interface TableDataType {
  rejectedType: string | null;
  rejectedTypeClaims: number | null;
  rejectedTypeProportion: number | null;
  rejectedTypeProportionStr: string | null;
  rejectedReason: string | null;
  rejectedReasonClaims: number | null;
  rejectedReasonProportion: number | null;
  rejectedReasonProportionStr: string | null;
}

import {
  defineComponent,
  ref,
  watch,
  onMounted,
  reactive,
  toRefs,
  computed,
} from "vue";
import DM from "../components/downloadModal.vue";
import { debounce } from "lodash-es";
import BuSelector from "@/views/SalesReporting/components/BuSelector.vue";
import { getCheckingRoundList } from "@/API/salesReporting/claimCheckingReport";
import moment, { Moment } from "moment";
import { message, TreeSelect } from "ant-design-vue";
import { qmOptions } from "@/views/SalesReporting/type";
import {
  getFailedReasonAnalysis,
  getRetrieveRegionList,
  getDealerByGroupId,
  getRetrieveSubRegionList,
  getAllParentBu,
} from "@/API/salesReporting";
import { getOfferType } from "@/API/claim/delaerClaim/delaer";
import { useStore } from "vuex";
import downloadFile from "@/utils/claim/downloadFile";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default defineComponent({
  components: {
    BuSelector,
    DM,
  },
  setup() {
    const allDealerCode = ref<string[]>([]);
    const isDealer = ref(true);
    const store = useStore();
    if ((store.state.user as any).legalEntity.entityCode !== "Dealer") {
      isDealer.value = false;
    }

    const usersLst = ref<any[]>([]);
    const temporaryArray = ref<any[]>([]); // 动态下拉框的值
    const offerTypeArray = ref<any[]>([]); //offerType的值

    const allDealer = ref<any[]>([]);

    interface SearchDataType {
      bu: string | null;
      payoutRound: Array<string>;
      payoutRoundYear: string | null;
      payoutRoundMonth: Array<string>;
      year: string | null;
      quarter: Array<string>;
      dataView: string | null;
      dynamic: string | null;
      dynamicArray: Array<string>;
      dynamicArrayObj: Array<any>;
    }

    const searchData = reactive<SearchDataType>({
      bu: "",
      payoutRound: [],
      payoutRoundYear: "",
      payoutRoundMonth: [],
      year: "",
      quarter: [],
      dataView: isDealer.value ? "Program Category" : null,
      dynamic: null,
      dynamicArray: [],
      dynamicArrayObj: [],
    });

    const buList = ref<any[]>([]);
    const getBuList = () => {
      getAllParentBu().then((res) => {
        buList.value = res;
        //console.log(buList.value);
        buList.value = buList.value.filter((bu: any) => {
          return bu.parentId === null;
        });
        buList.value = buList.value.filter((bu: any) => {
          return bu.nameEn !== "Companycar";
        });
      });
    };
    getBuList();

    const buNameIdMap = computed(() => {
      const buLst = buList.value.reduce((maps: any, bu: any) => {
        //console.log(3333, maps, bu);
        return { ...maps, [bu.nameCn]: bu.id };
      }, {});

      return buLst;
    });
    //payoutRound初始化
    const payoutRoundList = ref([]);
    const getCheckingRound = (buName: string) => {
      //console.log(123, buName);
      getCheckingRoundList({ buLst: buNameIdMap.value[buName] }).then(
        (res: any) => {
          payoutRoundList.value = [];
          payoutRoundList.value = res;
        }
      );
    };

    const isPayoutRound = computed(() => {
      return payoutRoundList.value.length;
    });

    const selectPayoutRound = (value: string) => {
      //console.log(22222222, value);
      getCheckingRound(value);
    };

    const openPayoutRoundYear = ref<boolean>(false);

    const handlerOpenPayoutRoundChange = (status: any): void => {
      openPayoutRoundYear.value = status;
    };
    const handlerPanelPayoutRoundChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      searchData.payoutRoundYear = time as string;
      openPayoutRoundYear.value = false;
    };

    //period初始化
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      searchData.year = time as string;
      openProgramPeriodYear.value = false;
    };

    let resetBu = "";
    const initValue = (value: string) => {
      //console.log(11111, value);
      searchData.bu = value;
      resetBu = value;
      getCheckingRound(value);
    };

    const getMonth = (quarter: number) => {
      let array: Array<string> = [];
      switch (quarter) {
        case 1:
          array = ["01", "02", "03"];
          break;
        case 2:
          array = ["04", "05", "06"];
          break;
        case 3:
          array = ["07", "08", "09"];
          break;
        case 4:
          array = ["10", "11", "12"];
          break;
      }
      return array;
    };

    const reset = () => {
      searchData.bu = resetBu;
      (searchData.payoutRound = []), (searchData.year = "");
      searchData.quarter = [];
      searchData.payoutRoundYear = null;
      searchData.payoutRoundMonth = [];
      searchData.dataView = isDealer.value ? "Program Category" : null;
      searchData.dynamic = null;
      searchData.dynamicArray = [];
      searchData.dynamicArrayObj = [];
      searchData.year = moment().format("YYYY");
      searchData.payoutRoundYear = moment().format("YYYY");
      const array = getMonth(moment().quarter());
      searchData.quarter = array;
      searchData.payoutRoundMonth = array;
    };

    const mergeIndexProxy = ref<number[]>([]);
    const mergeLengthIndexProxy = ref<number[]>([]);

    const tableHeight = ref(0);

    const renderContent = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any,
      };
      if (mergeIndexProxy.value.length) {
        for (let i = 0; i < mergeIndexProxy.value.length; i++) {
          if (index === mergeIndexProxy.value[i]) {
            obj.props.rowSpan = mergeLengthIndexProxy.value[i] + 1;
          }
          if (
            index > mergeIndexProxy.value[i] &&
            index <= mergeIndexProxy.value[i] + mergeLengthIndexProxy.value[i]
          ) {
            obj.props.rowSpan = 0;
          }
        }

        return obj;
      } else {
        return obj;
      }
    };

    const mergeIndexProxyNonClosed = ref<number[]>([]);
    const mergeLengthIndexProxyNonClosed = ref<number[]>([]);
    const renderContentNonClosed = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any,
      };
      if (mergeIndexProxyNonClosed.value.length) {
        for (let i = 0; i < mergeIndexProxyNonClosed.value.length; i++) {
          if (index === mergeIndexProxyNonClosed.value[i]) {
            obj.props.rowSpan = mergeLengthIndexProxyNonClosed.value[i] + 1;
          }
          if (
            index > mergeIndexProxyNonClosed.value[i] &&
            index <=
              mergeIndexProxyNonClosed.value[i] +
                mergeLengthIndexProxyNonClosed.value[i]
          ) {
            obj.props.rowSpan = 0;
          }
        }

        return obj;
      } else {
        return obj;
      }
    };

    const columns = ref<any[]>([
      {
        title: "核查未通过类型",
        dataIndex: "rejectedType",
        customRender: renderContent,
        width: 200,
      },
      {
        title: "项目数量",
        dataIndex: "programNo",
        customRender: renderContent,
        width: 200,
      },
      {
        title: "最终核查未通过数量",
        dataIndex: "rejectedTypeClaims",
        customRender: renderContent,
        width: 150,
      },
      {
        title: "比例%",
        dataIndex: "rejectedTypeProportionStr",
        customRender: renderContent,
        width: 150,
      },
      {
        title: "核查未通过原因",
        dataIndex: "rejectedReason",
      },
      {
        title: "核查未通过数量",
        dataIndex: "rejectedReasonClaims",
        width: 150,
      },
      {
        title: "比例%",
        dataIndex: "rejectedReasonProportionStr",
        width: 150,
      },
    ]);

    const nonClosedColumns = [
      {
        title: "检查未通过类型",
        dataIndex: "rejectedType",
        customRender: renderContentNonClosed,
        width: 200,
      },
      {
        title: "项目数量",
        dataIndex: "programNo",
        customRender: renderContentNonClosed,
        width: 200,
      },
      {
        title: "核查未通过数量",
        dataIndex: "rejectedTypeClaims",
        customRender: renderContentNonClosed,
        width: 150,
      },
      {
        title: "比例%",
        dataIndex: "rejectedTypeProportionStr",
        customRender: renderContentNonClosed,
        width: 150,
      },
      {
        title: "核查未通过原因",
        dataIndex: "rejectedReason",
      },
      {
        title: "核查未通过数量",
        dataIndex: "rejectedReasonClaims",
        width: 150,
      },
      {
        title: "比例%",
        dataIndex: "rejectedReasonProportionStr",
        width: 150,
      },
    ];
    const dataSource = ref<TableDataType[]>([]);
    const nonClosedDataSource = ref<TableDataType[]>([]);

    function arrAsc(a: TableDataType, b: TableDataType) {
      //实现数组升序的方法
      if (a.rejectedTypeClaims === null) {
        return 1;
      }
      if ((a.rejectedTypeClaims as number) < (b.rejectedTypeClaims as number)) {
        return 1;
      } else if (
        (a.rejectedTypeClaims as number) > (b.rejectedTypeClaims as number)
      ) {
        return -1;
      } else {
        return 0;
      }
    }
    const userState = reactive<UserStateType>({
      value: [],
      fetching: false,
      userData: [],
    });
    const clearDynamic = (value: string) => {
      searchData.dynamic = null;
      searchData.dynamicArray = [];
      searchData.dynamicArrayObj = [];
      temporaryArray.value = [];
      userState.userData = [];
      switch (value) {
        case "Dealer Group":
          console.log("Dealer Group");
          break;
        case "Dealer":
          console.log("Dealer");
          console.log((store.state.user as any).orgid);
          getDealerByGroupId((store.state.user as any).orgid).then((res) => {
            const array: Array<any> = [];
            for (let i = 0; i < res.length; i++) {
              array.push({
                label: res[i].dealerInfo.dealerNameCn,
                value: res[i].dealerInfo.dealerCode,
              });
            }
            allDealer.value = array;
          });
          break;
        case "Region":
          // console.log(searchData.bu);
          getRetrieveRegionList(
            buNameIdMap.value[searchData.bu as string]
          ).then((res) => {
            // console.log(res)
            const array = [];
            for (let i = 0; i < res.length; i++) {
              array.push({
                key: res[i].id,
                id: res[i].id,
                value: res[i].regionEn,
              });
            }
            temporaryArray.value = array;
          });
          break;
        case "Sub Region":
          // console.log(searchData.bu);
          getRetrieveSubRegionList(
            buNameIdMap.value[searchData.bu as string]
          ).then((res) => {
            // console.log(res)
            const array = [];
            for (let i = 0; i < res.length; i++) {
              array.push({
                key: res[i].id,
                id: res[i].id,
                value: res[i].regionEn,
              });
            }
            temporaryArray.value = array;
          });
          break;
        case "Program Category":
          console.log("rogram Category");
          temporaryArray.value = offerTypeArray.value;
          break;
      }

      // temporaryArray
    };

    getOfferType().then((res: any): void => {
      for (let i = 0; i < res.incentiveCategory[0].offerType.length; i++) {
        offerTypeArray.value.push({
          key: res.incentiveCategory[0].offerType[i].pathKey,
          value: res.incentiveCategory[0].offerType[i].nameCn,
          id: res.incentiveCategory[0].offerType[i].id,
        });
      }
      for (let i = 0; i < res.incentiveCategory[1].offerType.length; i++) {
        offerTypeArray.value.push({
          key: res.incentiveCategory[1].offerType[i].pathKey,
          value: res.incentiveCategory[1].offerType[i].nameCn,
          id: res.incentiveCategory[1].offerType[i].id,
        });
      }
      // offerTypeArray.value.push(res.incentiveCategory[0])
    });

    const downloadTypeVisible = ref(false);
    const downloadType = ref(1);

    const download = () => {
      downloadTypeVisible.value = true;
    };

    interface ParamType {
      currentDealerCodes: Array<string>;
      region: Array<string>;
      subRegion: Array<string>;
      programCategory: Array<string>;
      bu: Array<string>;
      programPeriod: string;
      dealerGroup: Array<string>;
      // dealerName: Array<string>;
      downType: number | null;
      payoutRoundYear: string | null;
      payoutRoundMonth: Array<string>;
      programPeriodYear: string | null;
      programPeriodMonth: Array<string>;
      language: string;
    }

    const getParams = (isDownload: boolean) => {
      const array = [];
      for (let i = 0; i < searchData.payoutRoundMonth.length; i++) {
        if (searchData.payoutRoundMonth[i][0] == "Q") {
          const quarter = parseInt(searchData.payoutRoundMonth[i][1]);
          if (quarter == 4) {
            array.push(
              searchData.payoutRoundYear + "-" + ((quarter - 1) * 3 + 1)
            );
            array.push(
              searchData.payoutRoundYear + "-" + ((quarter - 1) * 3 + 3)
            );
            array.push(
              searchData.payoutRoundYear + "-" + ((quarter - 1) * 3 + 2)
            );
          } else {
            array.push(
              searchData.payoutRoundYear + "-0" + ((quarter - 1) * 3 + 1)
            );
            array.push(
              searchData.payoutRoundYear + "-0" + ((quarter - 1) * 3 + 3)
            );
            array.push(
              searchData.payoutRoundYear + "-0" + ((quarter - 1) * 3 + 2)
            );
          }
        } else {
          array.push(
            searchData.payoutRoundYear + "-" + searchData.payoutRoundMonth[i]
          );
        }
      }
      const array2 = [];
      for (let i = 0; i < searchData.quarter.length; i++) {
        if (searchData.quarter[i][0] == "Q") {
          const quarter = parseInt(searchData.quarter[i][1]);
          if (quarter == 4) {
            array2.push(searchData.year + "-" + ((quarter - 1) * 3 + 1));
            array2.push(searchData.year + "-" + ((quarter - 1) * 3 + 3));
            array2.push(searchData.year + "-" + ((quarter - 1) * 3 + 2));
          } else {
            array2.push(searchData.year + "-0" + ((quarter - 1) * 3 + 1));
            array2.push(searchData.year + "-0" + ((quarter - 1) * 3 + 3));
            array2.push(searchData.year + "-0" + ((quarter - 1) * 3 + 2));
          }
        } else {
          array2.push(searchData.year + "-" + searchData.quarter[i]);
        }
      }
      const params: ParamType = {
        // payoutRound: searchData.payoutRound,
        language: "CN",
        currentDealerCodes: allDealerCode.value,
        region: [],
        subRegion: [],
        programCategory: [],
        bu: [searchData.bu as string],
        programPeriod: "",
        dealerGroup: [],
        // dealerName: [],
        downType: null,
        payoutRoundYear: searchData.payoutRoundYear,
        payoutRoundMonth: array,
        programPeriodYear: searchData.year,
        programPeriodMonth: array2,
      };

      // const qm = searchData.quarter;
      // if (qm) {
      //     if (qm[0] === 'Q') {
      //         params.programPeriod = `${searchData.year}-${qm[1]}`;
      //     } else {
      //         params.programPeriod = `${searchData.year}-${
      //             (searchData.quarter as any)[1]
      //         }-${qm}`;
      //     }
      // } else {
      //     params.programPeriod = searchData.year;
      // }
      let arrayObj: Array<string> = [];
      switch (searchData.dataView as any) {
        case "Dealer Group":
          arrayObj = [];
          for (let i = 0; i < searchData.dynamicArrayObj.length; i++) {
            arrayObj.push(searchData.dynamicArrayObj[i].key);
          }
          params.dealerGroup = arrayObj;
          break;
        case "Dealer":
          arrayObj = [];
          for (let i = 0; i < searchData.dynamicArrayObj.length; i++) {
            arrayObj.push(searchData.dynamicArrayObj[i].key);
          }
          params.currentDealerCodes = arrayObj;
          break;
        case "Region":
          params.region = searchData.dynamicArray;
          break;
        case "Sub Region":
          params.subRegion = searchData.dynamicArray;
          break;
        case "Program Category":
          params.programCategory = searchData.dynamicArray;
          break;
      }

      if (isDownload) {
        params.downType = downloadType.value;
      }
      return params;
    };

    const handleOk = (data: Array<any>) => {
      if (data.length > 1) {
        downloadType.value = 3;
      } else {
        downloadType.value = data[0].sign;
      }
      //
      const params = getParams(true);
      const downloadParams = {
        url: `/claimapi/failedReasonAnalysis/download`,
        method: "post",
        params: params,
      };
      downloadFile(
        downloadParams,
        "application/vnd-excel; char-set=UTF-8"
      ).then((res: any) => {
        downloadTypeVisible.value = false;
      });
    };

    const sortArray1 = ref<any[]>([]);

    function claimSortByName(data: Array<any>) {
      let sign = false;
      for (let i = 0; i < data.length - 1; i++) {
        const dataV1 = data[i];
        const dataV2 = data[i + 1];
        if (
          dataV1.rejectedTypeClaims === dataV2.rejectedTypeClaims &&
          dataV2.rejectedType > dataV1.rejectedType
        ) {
          [data[i], data[i + 1]] = [data[i + 1], data[i]];
          sign = true;
        }
      }

      if (sign) {
        claimSortByName(data);
      } else {
        sortArray1.value = data;
      }
    }

    const rDataArray = ref<any[]>([]);
    function claimSort(data: Array<any>) {
      let sign = false;
      for (let i = 0; i < data.length - 1; i++) {
        const dataV1 = data[i];
        const dataV2 = data[i + 1];
        if (
          dataV1.rejectedType === dataV2.rejectedType &&
          dataV2.rejectedReasonProportion > dataV1.rejectedReasonProportion
        ) {
          [data[i], data[i + 1]] = [data[i + 1], data[i]];
          sign = true;
        }
      }

      if (sign) {
        claimSort(data);
      } else {
        rDataArray.value = data;
      }
    }

    const calculateMergeIndex = (data: any) => {
      const mergeIndex = []; //需要合并的起始角标
      const mergeLengthIndex = []; //起始角标下多少个
      let isMerge = false; //是否有合并了
      let isMergeNum = 0; //记录合并了几个

      if (data) {
        for (let i = 0; i < data.length - 2; i++) {
          const data1 = data[i];
          const data2 = data[i + 1];
          if (data1.rejectedType === data2.rejectedType && isMerge) {
            isMerge = true;
            isMergeNum++;
          }
          if (data1.rejectedType === data2.rejectedType && !isMerge) {
            isMerge = true;
            isMergeNum++;
            mergeIndex.push(i);
          }

          if (data1.rejectedType !== data2.rejectedType && isMerge) {
            mergeLengthIndex.push(isMergeNum);
            isMergeNum = 0;
            isMerge = false;
          }
          if (i === data.length - 3 && isMerge) {
            mergeLengthIndex.push(isMergeNum);
          }
        }
      }
      return { index: mergeIndex, lengthIndex: mergeLengthIndex };
    };

    const getTableData = async () => {
      if (allDealerCode.value.length === 0) {
        if (isDealer.value) {
          allDealerCode.value.push(
            (store.state.user as any).organization.entityCode
          );
        } else {
          await getDealerByGroupId((store.state.user as any).orgid).then(
            (res) => {
              for (let i = 0; i < res.length; i++) {
                allDealerCode.value.push(res[i].dealerInfo.dealerCode);
              }
            }
          );
        }
      }

      if (!searchData.payoutRoundYear) {
        message.error("Payout Round (Year) and BU is must select");
        return;
      }

      const params = getParams(false);
      getFailedReasonAnalysis(params).then((res) => {
        //根据rejectedTypeClaims，进行排序
        const sortData = (res["Closed"] ?? []).sort(arrAsc);
        //对rejectedTypeClaims相同，再用名称进行排序
        claimSortByName(sortData);
        //继续根据Recject Claim排序（TS的返回值报错好烦。。。）
        claimSort(sortArray1.value);
        const data = rDataArray.value;
        dataSource.value = data;

        const mergeIndex = []; //需要合并的起始角标
        const mergeLengthIndex = []; //起始角标下多少个
        let isMerge = false; //是否有合并了
        let isMergeNum = 0; //记录合并了几个
        for (let i = 0; i < data.length - 2; i++) {
          const data1 = data[i];
          const data2 = data[i + 1];
          if (data1.rejectedType === data2.rejectedType && isMerge) {
            isMerge = true;
            isMergeNum++;
          }
          if (data1.rejectedType === data2.rejectedType && !isMerge) {
            isMerge = true;
            isMergeNum++;
            mergeIndex.push(i);
          }

          if (data1.rejectedType !== data2.rejectedType && isMerge) {
            mergeLengthIndex.push(isMergeNum);
            isMergeNum = 0;
            isMerge = false;
          }
          if (i === data.length - 3 && isMerge) {
            mergeLengthIndex.push(isMergeNum);
          }
        }
        mergeIndexProxy.value = mergeIndex;
        mergeLengthIndexProxy.value = mergeLengthIndex;

        nonClosedDataSource.value = res["Non-Closed"];
        const nonClosedIndex = calculateMergeIndex(res["Non-Closed"]);
        mergeIndexProxyNonClosed.value = nonClosedIndex.index;
        mergeLengthIndexProxyNonClosed.value = nonClosedIndex.lengthIndex;
      });
    };

    onMounted(() => {
      tableHeight.value = window.innerHeight - 270;
      searchData.year = moment().format("YYYY");
      searchData.payoutRoundYear = moment().format("YYYY");

      // const quarter = moment().quarter();
      const array = getMonth(moment().quarter());
      searchData.quarter = array;
      searchData.payoutRoundMonth = array;
      searchData.bu = "MBPC";
      getTableData();

      if (isDealer.value) {
        clearDynamic("Program Category");
      }
    });
    interface UserStateType {
      value: Array<any>;
      fetching: boolean;
      userData: Array<any>;
    }

    watch(userState.value, () => {
      userState.fetching = false;
      userState.userData = [];
    });
    const timeid = ref<any>();
    const fetchUser = (value: string) => {
      // console.log(value);
      // console.log(searchData.dynamicArray)
      userState.userData = [];
      userState.fetching = true;
      if (timeid.value != null) {
        clearTimeout(timeid.value);
      }
      timeid.value = setTimeout(() => {
        //console.log(value);
        for (let i = 0; i < allDealer.value.length; i++) {
          if (allDealer.value[i].label.indexOf(value) > -1) {
            userState.userData.push(allDealer.value[i]);
          }
        }
        // userState.userData = [
        //     {
        //         label:'1',
        //         value:'1'
        //     },
        //     {
        //         label:'2',
        //         value:'2'
        //     },
        //     {
        //         label:'3',
        //         value:'3'
        //     }
        // ];
        userState.fetching = false;
        clearTimeout(timeid.value);
      }, 1000);
    };
    // (value => {
    //     console.log('fetching user', value);

    // }, 300);
    const downloadTableData = [
      {
        file: "激励申请核查失败原因分析报表",
        sign: 2,
      },
      {
        file: "激励申请核查失败原因分析报表源数据",
        sign: 1,
      },
    ];
    const changePayoutRoundYear = (value: string) => {
      if (!value) {
        searchData.payoutRoundMonth = [];
      }
    };
    const changeYear = (value: string) => {
      if (!value) {
        searchData.quarter = [];
      }
    };
    return {
      changeYear,
      changePayoutRoundYear,
      downloadTableData,
      isDealer,
      openPayoutRoundYear,
      handlerOpenPayoutRoundChange,
      handlerPanelPayoutRoundChange,
      ...toRefs(userState),
      fetchUser,
      usersLst,
      downloadTypeVisible,
      downloadType,
      download,
      handleOk,
      temporaryArray,
      selectPayoutRound,
      columns,
      nonClosedColumns,
      dataSource,
      nonClosedDataSource,
      tableHeight,
      clearDynamic,
      getTableData,
      isPayoutRound,
      SHOW_PARENT,
      qmOptions,
      openProgramPeriodYear,
      reset,
      initValue,
      ...toRefs(searchData),
      payoutRoundList,
      handlerOpenProgramPeriodChange,
      handlerPanelProgramPeriodChange,
    };
  },
});
